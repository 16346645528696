.navBar {
  background: rgba(236, 236, 236, 0.7);
  /* padding-bottom: 50px; */
}


/* Estilo para el ícono del navbar-toggler en color blanco */
.navbar-toggler-custom {
  background-color: white; 
  border-color: white; 
  color: white !important;
margin-left:15px;
}


.nav4 {
  color:#1f2857
}

.nav4:hover {
  background: #1f2857;
  color: white;
}


#txtblanco {
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  text-align: center;
  font-weight: 500;
  font-weight: bold;
  margin-right: 25px;
  
  /* padding-left: 15px; */
}

#txtblanco:hover {
  color:white;
  padding-left: 7px;
  padding-right: 7px;
  text-align: center;
  
  /* padding-left: 15px; */
}





.fixed-top-nav {
  /* height: 80px;  */
  display: flex;
  align-items: center;
  background-color: #2597cf;
}

.navbar-toggler-icon {
  /* margin-left: 30px; */
color: white;
}

/* #basic-navbar-nav {
  font-weight: normal;
  line-height: 1.2;
} */

.navbar-toggler {
  margin-right: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: white;
}


/* 
.navbar-toggler-icon {
  color: #ff0000; 
}



.nav3:hover {
  background: rgba(	100, 195, 208, 0.7);
  
}

#nav4:hover {
  background: rgba(	100, 195, 208, 0.7);
}

.navBar{
  font-weight: 600;
} */








#menu {
  margin-right: 12px;
}


.navbar{
  background-color: #1f2857;
}

.logoMenu {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:30px;
  margin-bottom:30px;
}

@media only screen and (max-width: 991px) {
  .logoMenu {
    max-width: 838px;

 }
 }




@media only screen and (max-width: 400px) {
  .logoMenu {
width: 250px;
 }
 }







/* home */

.banner {
  width: 100%;
  height: auto;
}



/* Merch-Partner */

.image-gallery-container {
  max-width: 900px;
  margin: 0 auto; /* Centrar el contenedor en la página */
  text-align: center;

}

.image-container p {
  font-weight: 600;
  color:#1f2857;
  margin-top: 5px;
}

.image-container p:hover{
color: #2597cf;
}

.image-container a {
  text-decoration: none;
}



@media (max-width: 600px) {
  .image-container {
    width:250px;
    margin-left: auto;
    margin-right: auto;
  }
}



.image-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-gallery img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s; 
  /* margin-bottom: 10px; */
}
.image-gallery img:hover {
  transform: scale(1.1); /* Escala la imagen al 110% cuando el mouse está sobre ella */
}

/* Estilos para hacer que las imágenes sean responsive */
@media (max-width: 600px) {
  .image-gallery img {
    width: 100%;
    height: auto;
  }
}


/* no hace  nada */
.merchPartner{
  min-width: 30px;
  width: 200px;
  height: auto;
  margin: 5px; 
  /* Agrega un margen entre los componentes */
  /* border: 2px solid #ccc; */
  padding: 15px;
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
  
}
.Title {
max-width: 870px;
margin-left: auto;
margin-right: auto;
margin-top: 15px;
text-align: center;

}

.Title h2{
color: #1f2857;
font-weight: bold;
font-size: 40px;
margin-bottom: 30px;
/* font-size: 'Effra_Trial_SBd.ttf'; */
}

.Title h3{
  font-size: 20px;
  color: rgb(62, 62, 62);
}

.Title p{
  font-size: 15px;
  color: rgb(79, 79, 79);
}


.responsiveMerchPartner{
  max-width: 100%;
  height: auto;
  /* margin-right: 15px;
  margin-left: 15px; */
  min-width: 100px;
  text-align: left;
  width: 300px;
  text-align: center;
}

.responsiveMerchPartner h2 {
  color: #1f2857;
  font-size: 15px;
  
}






@font-face {
  font-family: 'MiFuente';
  src: url('./assets/fonts/Effra_Trial_SBd.ttf') format('truetype');
  src: url('./assets/fonts/Effra_Trial_SBd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



.responsiveMerchPartner p {
  line-height: 1.3;
}

.responsiveMerchPar {
  font-size: 15px;
  text-align: center;
}

.responsiveMerchPar h3 {
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  color: #1f2857;
}



.imagenMerchPartner {
  min-width: auto;
  width: 200px;
  max-width: 100%;
  height: auto;
}



.imagenMerchPartner {
  border-radius: 50%; /* Hace que la imagen sea circular */
  transition: border 0.3s ease-in-out; /* Agrega una transición suave al efecto */
  transition: opacity 0.3s ease-in-out; 
  /* Agrega estilos adicionales de tu imagen aquí, como tamaño, márgenes, etc. */
}

.imagenMerchPartner:hover {
  border: 3px solid #2597cf; 
  opacity: 0.8; 
}








.merchPartner-container {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  height: auto;
  min-width: 300px;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-top: 40px; */
}













/* .Title {  
  margin-left: 5px; 
  margin-right: 5px; 
  margin-top: 15px;
  
} */


.container {
  /* max-width: 1000px; */
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 50px; */
  /* padding-bottom: 30px; */
  /* margin-top: 30px; */
  /* margin-bottom: 80px; */
}

#containerPartner{
  margin-bottom: 80px;
}

.container2 {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}


.containerCentered {
  margin-top: 50px;
  /* max-width: 250px; */
  min-width: 100px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

}



/* #background-colour {
  background-color:  rgba(236, 236, 236, 0.7);
} */



.equipmentContent {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

.list-group {
  display: inline-block; /* Hace que los elementos se ajusten al tamaño del contenido */
  padding: 8px 12px; /* Ajusta el relleno interno para que haya espacio alrededor del contenido */
  font-size: 16px; /* Tamaño de fuente base, puedes ajustarlo según tus preferencias */
  /* Otros estilos adicionales si es necesario */
}




/* equipment item */

/* EquipmentItem.css (o el nombre que hayas dado al archivo) */
.equipmentItem {
  border: 1px solid #1f28571a;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%; /* Ajusta el ancho al valor deseado */
  height: 60px; /* Establece la altura fija */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.equipmentItem h3 {
  font-size: 25px;
}

.equipmentItem.expanded {
  background-color: #f0f0f0;
}

.imageContainer {
  flex: 0 0 100px; /* Anchura fija para la imagen */
  margin-right: 20px;
  margin-bottom: 30px;
}

.imageContainer img {
  max-width: 50%;
  height: auto;
  float: left;
  margin-left: 20px;
  margin-right: 15px;
}

.textContainer {
  flex: 1;
  display: flex; /* Utiliza flexbox para organizar el texto */
  flex-direction: column; /* Apila el texto verticalmente */
}

.textContainer h3 {
  margin: 0;
  font-size: 20px;
}

.textContainer p {
  margin-top: 15px;
  font-size: 16px;
  color: #666;
}

.textContainer ul {
  margin: 0;
  font-size: 16px;
  color: #666;
}

.textContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  margin-top: 20px;
}

.textContainer table, .textContainer th, .textContainer td {
  border: 1px solid #ccc;
  padding: 8px;
}

.textContainer th {
  background-color: #f0f0f0;
  font-weight: bold;
}


.equipmentItem h3::before {
  content: "• "; /* Agrega una viñeta antes del nombre del ítem */
}



/* footer */
.footer {
  background-color: #1f2857;
}

.link-item:hover {
  color: #2597cf;

  /* color:white; */
  /* Tipo de viñeta */
}

/* .image-container p {
  font-weight: 600;
  color:#1f2857;
  margin-top: 5px;
}

.image-container p:hover{
color: #2597cf;
} */


@media only screen and (max-width: 1025px) {
  .containerp-4 {
  /* position: absolute; */
  /* max-width: 40%; */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
  min-width: 300px;

  text-align: center;
 }
 }

 @media only screen and (max-width: 991px) {
  #infoFooterTitulo {
  text-align: center;
 }
 }

 @media only screen and (max-width: 991px) {
  #infoFooterTitulo2 {
  text-align: center;
 }
 }

 @media only screen and (max-width: 991px) {
  #infoFooterTitulo3 {
  text-align: center;
 }
 }



/* Footer */

.footer {
  background-color: white;
  color: rgba(236, 236, 236, 0.7);
  position: relative; 
  /* margin-top:150px; */
  min-width: 300px;
}

.tituloFooter {
  font-size: 40px;  
  margin-top: 30px;
  text-align: left;
  color: #44828c;
}

.infocourseFooter {
  text-align: left;
  font-size: 23px;
  color: rgba(236, 236, 236, 0.7);
  line-height: 1.2;
}

.infocourseFooter p {
  margin-bottom: 30px;
}

h5 {
  margin-top: 50px;
  color: #44828c;
}

.text-uppercase {
  margin-top: 18px;
}


.myInformation {
  text-align: left;
  margin-bottom: 20px;
}

.myInformation p {
  margin-bottom: 1px;
}

.logoHeader {
  margin-top: 10px;
  margin-right: 20px;
}


#contacto {

  width: auto;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background:#1f2857;
 
}


.form-check {
  text-align: left;
}

.btnForm {
  text-align: right;

}

#btn {
  background: white;
  color: #1f2857;
  /* border-color: #2e595f; */
}



/* .logoFooter {
  text-align: left;
} */






/* Estilos para los contenedores de los enlaces */
.links-container {
  display: flex;
  flex-wrap: wrap; /* Para que los enlaces se ajusten a varias filas en pantallas pequeñas */
  justify-content: center; /* Justificar al centro horizontalmente */
  max-width: 800px; /* Ancho máximo para el contenedor de enlaces */
  margin: 0 auto; /* Centrar el contenedor en la pantalla */
}

/* Estilos para cada enlace */
.link-item {
  margin: 0 20px; /* Espacio entre los enlaces */
  list-style-type: disc; 

  /* color:white; */
  /* Tipo de viñeta */
}

/* Estilo para el texto de los enlaces */
.text-black {
  color: black;
  text-decoration: none; /* Opcional: quitar el subrayado de los enlaces */
}

/* Estilo para el texto de los enlaces al pasar el mouse */
.text-black:hover {
  text-decoration: underline; /* Opcional: subrayar los enlaces al pasar el mouse */
}



/* .text-center {
  background-color: #1f2857;
} */

.merchService h2{
  color:#2597cf;
  font-weight: 800;
  font-size: 21.5px;
  margin-bottom: 0px;
}

.merchService p{
color:white;
font-size: 20px;
}


.list-unstyled  {
  color: white;
}


.links-container  {
  color: white;
}

.linkFooter{
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.linkFooter:hover {
  text-decoration: underline;
  color: #2597cf;
}


.registerForm h2{
  color: white;
  font-size: 800;
  margin-bottom: 20px;
}

#contact {
  text-align: left;
  margin-bottom: 25px;
}




@media only screen and (max-width: 992px) {
  #contact {
    text-align: center;
    margin-bottom: 25px;
 }
 }



 .equipmentHire {
  /* margin-bottom: 50px; */
  background-color: rgba(236, 236, 236, 0.175);
  padding-bottom: 50px;
  padding-top: 10px;
  
}



.expandableItem h3{
  transition: background-color 0.3s; /* Agrega una transición suave para el cambio de color */
  font-family: 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 15px;
  color:#1f2857;
  margin-left: 15px;
}

.expandableItem h3:hover {
  color:#2597cf;
  cursor: pointer;
}


.expandableItem h3::before{
    content: "▼"; 
    margin-right: 10px; 
    color: black;
  }

  .expandableItem h3::before:hover{
    color:#2597cf;
    cursor: pointer;
  }




 /* EHire */

 #scroll {
  text-align: left;
 }

 #scroll2 {
  text-align: right;
 }

 .img-fluid:hover {
size:105%;
 }

 .p-5 h2{
  font-size: 30px;
  font-weight: 400;
 }

 .p-5 p{
  color: rgb(79, 79, 79);
 }

 .img-fluid {
  border-radius: 50%; /* Hace que la imagen sea circular */
  transition: border 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Agrega una transición suave a los efectos */
  /* Agrega estilos adicionales de tu imagen aquí, como tamaño, márgenes, etc. */
}

.img-fluid:hover {
  border: 4px solid #1f2857;
  transform: scale(1.05); /* Escala la imagen al 110% al hacer hover */
  opacity: 0.9;
}


.buttonFormat {
  background-color: #1f2857; /* Color de fondo */
  color: #fff; /* Color del texto */
  padding: 7px 15px; /* Espaciado interno (arriba y abajo, izquierda y derecha) */
  border: none; /* Borde del botón (ninguno) */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambiar el cursor al pasar por encima del botón */
  font-size: 16px; /* Tamaño del texto */
  font-weight: bold; /* Texto en negrita */
  text-align: center; /* Alineación del texto al centro */
  text-decoration: none; /* Sin subrayado */
  display: inline-block; /* Mostrar como bloque en línea */
}

/* Estilos cuando se pasa el ratón por encima del botón */
.buttonFormat:hover {
  background-color: #2597cf; /* Cambiar color de fondo al pasar el ratón por encima */
}

#equipmentHire {
  padding-top: 30px;
  Font-size: 30px; 
  font-family: 'Effra_Trial_SBd.ttf', Arial black, sans-serif; /* Reemplaza Arial y sans-serif con fuentes de respaldo si es necesario */
  font-weight: 700; /* Puedes ajustar esto según tus necesidades */
  font-style: normal;
  text-transform: uppercase;
}

#equipmentHire h2{

Font-size: 30px; 
font-family: 'Effra_Trial_SBd.ttf', Arial black, sans-serif; /* Reemplaza Arial y sans-serif con fuentes de respaldo si es necesario */
font-weight: 700; /* Puedes ajustar esto según tus necesidades */
font-style: normal;
text-transform: uppercase;
}



#merchServices {
  Font-size: 30px; 
  font-family: 'Effra_Trial_SBd.ttf', Arial black, sans-serif; /* Reemplaza Arial y sans-serif con fuentes de respaldo si es necesario */
  font-weight: 700; /* Puedes ajustar esto según tus necesidades */
  font-style: normal;
  text-transform: uppercase;
}

#merchPartner {
  Font-size: 30px; 
  font-family: 'Effra_Trial_SBd.ttf', Arial black, sans-serif; /* Reemplaza Arial y sans-serif con fuentes de respaldo si es necesario */
  font-weight: 700; /* Puedes ajustar esto según tus necesidades */
  font-style: normal;
  text-transform: uppercase;
}




.recaptcha-container {
  display: flex;
  justify-content: flex-end; /* Esto justificará el contenido a la izquierda */
  align-items: center; /* Esto centrará verticalmente el contenido */
}





/* ABOUT US */


/* Estilos para el elemento con la clase ".aboutUs" en pantallas grandes */
.aboutUs {
  background-color: #1f2857;
  color: white;
  padding: 70px;
  padding-top: 150px;
  padding-bottom: 150px;
  text-align: center;
  font-family: 'MiFuente', Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* Media query para pantallas más pequeñas, por ejemplo, dispositivos móviles */
@media (max-width: 768px) {
  .aboutUs {
    padding: 30px; /* Reduce el relleno en pantallas más pequeñas */
    padding-top: 80px;
    padding-bottom: 80px;
    font-weight: 600; /* Puedes ajustar el peso de la fuente para pantallas más pequeñas */
    font-size: 16px; /* Puedes ajustar el tamaño de fuente para pantallas más pequeñas */
  }
  
  .aboutUs h2 {
    width: auto; /* Reinicia el ancho a automático para evitar problemas de desbordamiento de texto */
    margin-left: auto;
    margin-right: auto;
  }
}




/* nuevo */
/* Estilos para el div con la clase "trailer1" */
.trailer1 img {
  width: 350px;
  margin-left: 20px;
  float: left; /* Posiciona "trailer1" a la izquierda */
}

/* Estilos para el div con la clase "content-trailer" */
.content-trailer {
  margin-left: 420px; /* Añade espacio a la izquierda para "content-trailer" */
  color: #434343;
}



/* Estilos para el div con la clase "trailer2" */
.trailer2 {
  clear: both; /* Asegura que "trailer2" esté debajo de "content-trailer" */
  margin-bottom: 30px;
}

/* Estilos para las imágenes dentro de "trailer2" */
.trailer2 img {
  max-width: 100%; /* Ajusta el ancho de las imágenes para que se ajusten al contenedor */
  height: auto; /* Mantiene la relación de aspecto original */
  display: block; /* Elimina espacios en blanco adicionales debajo de las imágenes */
}

/* Media query para dispositivos más pequeños (por ejemplo, pantallas menores a 768px) */
@media (max-width: 768px) {
  /* Cambia el orden de apilado: primero el texto y luego la imagen */
  .container {
    flex-direction: column-reverse;
  }

  /* Ajusta los estilos para dispositivos más pequeños según sea necesario */
  .trailer1 img {
    width: 80%; /* Haz que la imagen ocupe todo el ancho del contenedor en dispositivos pequeños */
    margin-left: auto;
    margin-right: auto;
    display: flex;
  justify-content: center;
  align-items: center;
    float: none; /* Elimina el flotante en dispositivos pequeños */
  }

  .content-trailer {
  
    margin: 20px;
  }


.trailer2 {
  width: 80%; /* Haz que la imagen ocupe todo el ancho del contenedor en dispositivos pequeños */
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
justify-content: center;
align-items: center;

}

}



.dropdownContent {
  color: #434343;
  margin-left:20px;
  font-size: 20px;
}